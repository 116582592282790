
import API from "@/api/API";
import store from "@/store";
import Vue from "vue";
import { Dictionary } from "lodash";

export default Vue.extend({
  name: "RoleManager",

  data: () => ({
    model: {} as Dictionary<any>,
    currentTab: "" as string,
    loadData: false as boolean,
    schema: {
      role: {
        title: "" as string
      }
    } as Dictionary<any>,
    dataObject: {} as Dictionary<any>,
    dataObjectOriginal: {} as Dictionary<any>,
    firstGroupAction: {} as Dictionary<any>,
    columns: {} as Dictionary<any>,
    testModel: "store" as string
  }),

  async beforeRouteEnter(to, from, next) {
    try {
      const response = await API.roles().getRolePermissions(
        Number(to.params.id)
      );
      next(vm => {
        vm.schema = { ...response.schema };
        vm.schema.role = { title: response.schema.title };
        vm.dataObject = { ...response.dataObject };
        vm.prepareModel();
        vm.getFirstGroupActions();
        vm.loadData = true;
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  methods: {
    changeTab(value: string): void {
      this.currentTab = value;
    },
    icon(
      groupIndex: number,
      moduleIndex: number,
      moduleContent: any,
      actionIndex: number
    ): string {
      const model = this.dataObject[groupIndex].modules[moduleIndex].actions[
        moduleContent.actions[actionIndex].name
      ].fields;
      const items = moduleContent.actions[actionIndex].fields;

      if (model.length === items.length) {
        return "mdi-close-box";
      }
      if (model.length) {
        return "mdi-minus-box";
      }
      return "mdi-checkbox-blank-outline";
    },
    toggle(
      groupIndex: number,
      moduleIndex: number,
      moduleContent: any,
      actionIndex: number
    ): void {
      const model = this.dataObject[groupIndex].modules[moduleIndex].actions[
        moduleContent.actions[actionIndex].name
      ].fields;
      const items = moduleContent.actions[actionIndex].fields;

      this.$nextTick(() => {
        if (model?.length === items?.length) {
          model.splice(0, model.length);
        } else {
          model.splice(
            0,
            model.length,
            ...items.map((item: any) => item.value)
          );
        }
      });
    },
    getFirstGroupActions(): void {
      const { groups } = this.schema;

      for (const groupIndex in groups) {
        if (groups.hasOwnProperty(groupIndex)) {
          const [firstAction] = Object.keys(groups[groupIndex].actions);
          const [item] = firstAction;
          this.firstGroupAction[groupIndex] = item;
        }
      }
    },
    checkCurrentTab(value: string): void {
      if (!this.currentTab) {
        this.currentTab = value;
      }
    },
    prepareModel(): void {
      const dataObject = { ...this.dataObject };

      this.dataObject = { ...dataObject };
      this.dataObjectOriginal = JSON.parse(JSON.stringify(dataObject));
    },
    reset() {
      this.dataObject = JSON.parse(JSON.stringify(this.dataObjectOriginal));
    },
    async save(): Promise<void> {
      try {
        await this.$API.roles().editPermissions(Number(this.$route.params.id), {
          groups: this.dataObject
        });
        await this.$router.push("/roles");
        await this.$store.dispatch(
          "alert/showSuccess",
          "Права доступа успешно отредактированы."
        );
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    }
  }
});
