
import Vue from "vue";

export default Vue.extend({
  name: "Create",

  data: () => ({
    model: {} as any,
    errorMessages: {} as any,
    rules: {
      required: [
        (v: string): boolean | string =>
          !!v || "Поле обязательно для заполнения!"
      ]
    }
  }),

  computed: {
    computedRules(): object {
      return {
        min: this.minLength
      };
    }
  },

  methods: {
    minLength(number: number): Function {
      return (v: string): boolean | string =>
        (!!v && v.length >= number) || `Минимальная длина: ${number}`;
    },
    async submit(): Promise<void> {
      try {
        if ((this.$refs.form as any).validate()) {
          await this.$API.roles().create(this.model);
          await this.$router.push("/roles");
          await this.$store.dispatch(
            "alert/showSuccess",
            "Запись успешно добавлена."
          );
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
    }
  }
});
